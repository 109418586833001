import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, Typography } from '@mui/material';
// component
import DataLoader from 'components/common/DataLoader';
//interfaces, styles
import { RadioGroupControllerProps, SelectType } from 'interfaces';

const RadioGroupController: FC<RadioGroupControllerProps> = ({
  name,
  title,
  options,
  loading,
  isRequired,
  loaderRows = 1,
  showTitle = true,
  loaderColumns = 12,
  columnSize = 4,
  onChange: handleOnChange,
  handleOnChange: handleSubTypeOnChange,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { name, onChange, value, ref } = field;
        const { error } = fieldState;
        const { message } = error || {};
        return (
          <Fragment>
            {showTitle && (
              <Typography variant="body2" fontSize={14} fontWeight={600} marginBottom={2}>
                {isRequired ? `${title} *` : title}
              </Typography>
            )}
            {loading ? (
              <DataLoader columns={loaderColumns} rows={loaderRows} />
            ) : (
              <Grid container>
                <RadioGroup
                  row
                  ref={ref}
                  name={name}
                  value={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}>
                  {options?.map((item) => {
                    const { name, value, subTitle, isActive } = item || {};
                    return (
                      <Grid item xs={12} lg={columnSize} xl={columnSize} key={value}>
                        <FormControlLabel
                          value={value}
                          control={<Radio />}
                          label={`${name} ${subTitle ? subTitle : ''}`}
                          disabled={!(isActive ?? true)}
                          onChange={() => {
                            if (handleSubTypeOnChange) {
                              handleSubTypeOnChange(item as SelectType);
                            } else if (handleOnChange) {
                              handleOnChange();
                            } else {
                              onChange();
                            }
                          }}
                        />
                      </Grid>
                    );
                  })}
                </RadioGroup>
                {message ? <FormHelperText>{message}</FormHelperText> : <></>}
              </Grid>
            )}
          </Fragment>
        );
      }}
    />
  );
};

export default RadioGroupController;
