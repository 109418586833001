import { FC, Fragment, JSX } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Card, CardActions, CardContent, Grid } from '@mui/material';
// components
import InputController from 'controller/InputController';
import ProductTypesSelector from 'components/common/selectors/productType';
import ProductSubTypesSelector from 'components/common/selectors/productSubType';
// constants, styles, interfaces
import { AddProductSizeFormType, ProductTypeFormProps } from 'interfaces';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';
import {
  EMPTY_WEIGHT_TEXT,
  GUMMY_WEIGHT_TEXT,
  MAX_TEXT,
  MIN_TEXT,
  NAME_TEXT,
  PRODUCT_TYPE_TEXT,
  SAVE_TEXT,
  selectTypeInitialValues,
  SUB_PRODUCT_TYPE_TEXT,
} from 'constants/index';

const ProductSizeForm: FC<ProductTypeFormProps> = ({ isEdit = false, loading = false }): JSX.Element => {
  const { watch, setValue } = useFormContext<AddProductSizeFormType>();
  const { productType } = watch();

  const handleProductTypeChange = () => {
    setValue('subProductType', selectTypeInitialValues);
  };
  return (
    <Card sx={forInputLabelBg}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <InputController isRequired fieldType="text" name="name" disabled={loading} title={NAME_TEXT} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <InputController
              isRequired
              fieldType="number"
              name="emptyWtMg"
              disabled={loading}
              title={EMPTY_WEIGHT_TEXT}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <InputController
              fieldType="number"
              name="gummyWeight"
              disabled={loading}
              title={GUMMY_WEIGHT_TEXT}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <InputController isRequired fieldType="number" name="min" disabled={loading} title={MIN_TEXT} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <InputController isRequired fieldType="number" name="max" disabled={loading} title={MAX_TEXT} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ProductTypesSelector
              isRequired
              title={PRODUCT_TYPE_TEXT}
              name="productType"
              handleChange={handleProductTypeChange}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ProductSubTypesSelector
              name="subProductType"
              title={SUB_PRODUCT_TYPE_TEXT}
              disabled={!productType?.value}
              productTypeId={productType?.value}
            />
          </Grid>
        </Grid>
      </CardContent>

      {isEdit ? (
        <CardActions>
          <Box sx={flexMarginTopEndCenter}>
            <Button type="submit" variant="contained" size="small" disabled={loading}>
              {SAVE_TEXT}
            </Button>
          </Box>
        </CardActions>
      ) : (
        <Fragment />
      )}
    </Card>
  );
};

export default ProductSizeForm;
