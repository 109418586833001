// imports
import { FC } from 'react';
// components
import SidebarMain from 'components/common/SidebarMain';
import SidebarSubList from 'components/common/SubSidebar';
import { ExcipientBottleIcon, ExcipientIcon, ProductsIcon, SweetenerIcon } from 'assets/svgs';
// constants
import {
  BOTTLE_TEXT,
  PRODUCT_TEXT,
  EXCIPIENT_TEXT,
  EXCIPIENT_ROUTE,
  BOTTLE_SIZE_ROUTE,
  PRODUCT_TYPE_ROUTE,
  SWEETENER_SYSTEM_TEXT,
  SUPER_ADMIN_BOTTLE_LIST,
  SUPER_ADMIN_PRODUCT_LIST,
  SWEETENER_COMPONENT_ROUTE,
  SUPER_ADMIN_EXCIPIENT_LIST,
  SUPER_ADMIN_MANAGEMENT_LIST,
  SUPER_ADMIN_SWEETENER_LIST,
} from 'constants/index';

const ManagementList: FC = () => {
  return (
    <>
      <SidebarMain list={SUPER_ADMIN_MANAGEMENT_LIST} />
      <SidebarSubList
        defaultRoute={PRODUCT_TYPE_ROUTE}
        mainTitle={PRODUCT_TEXT}
        list={SUPER_ADMIN_PRODUCT_LIST}
        icon={ProductsIcon}
      />
      <SidebarSubList
        defaultRoute={EXCIPIENT_ROUTE}
        mainTitle={EXCIPIENT_TEXT}
        list={SUPER_ADMIN_EXCIPIENT_LIST}
        icon={ExcipientIcon}
      />

      <SidebarSubList
        defaultRoute={BOTTLE_SIZE_ROUTE}
        mainTitle={BOTTLE_TEXT}
        list={SUPER_ADMIN_BOTTLE_LIST}
        icon={ExcipientBottleIcon}
      />

      <SidebarSubList
        defaultRoute={SWEETENER_COMPONENT_ROUTE}
        mainTitle={SWEETENER_SYSTEM_TEXT}
        list={SUPER_ADMIN_SWEETENER_LIST}
        icon={SweetenerIcon}
      />
    </>
  );
};

export default ManagementList;
