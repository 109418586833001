import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import SupplierForm from './SupplierForm';
import { Alert } from 'components/common/Alert';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import {
  HTTP_STATUS,
  DUPLICATE_TEXT,
  DUPLICATE_SUPPLIER_TEXT,
  addSupplierInitialValues,
  SUPPLIER_IS_CREATING_TEXT,
} from 'constants/index';
import { supplierSchema } from 'validationSchema';
import { AddSupplierFormType, ParamType } from 'interfaces';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { SupplierPayload, useCreateSupplierMutation, useGetSupplierLazyQuery } from 'generated/graphql';

const Duplicate: FC = (): JSX.Element => {
  const navigate = useNavigate();

  const params = useParams<ParamType>();
  const { id } = params || {};

  const methods = useForm<AddSupplierFormType>({
    defaultValues: addSupplierInitialValues,
    resolver: yupResolver(supplierSchema),
  });

  const { handleSubmit, setValue } = methods;

  const [createSupplier, { loading: createLoading }] = useCreateSupplierMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createSupplier } = data;
      const { response } = createSupplier || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        navigate(-1);
        Alert.success(message || '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getSupplier, { loading: getLoading }] = useGetSupplierLazyQuery({
    onCompleted: (data) => {
      const { getSupplier } = data || {};
      const { response, supplier } = getSupplier || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(supplier as SupplierPayload['supplier']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });

  const onSubmit: SubmitHandler<AddSupplierFormType> = async (data) => {
    const { companyName, description, state, country, phoneNo, ...rest } = data || {};

    const { value, name } = country || {};

    await createSupplier({
      variables: {
        createSupplierInput: {
          contact: {
            ...rest,
            companyName,
            country: name,
            state: state?.value,
            ...(value && { countryCode: value }),
            ...(phoneNo && { phoneNo }),
          },
          supplier: {
            description,
            name: companyName,
          },
        },
      },
    });
  };

  const fetchSupplier = useCallback(async () => {
    id &&
      (await getSupplier({
        variables: {
          getSupplierInput: {
            id,
          },
        },
      }));
  }, [id, getSupplier]);

  const setValues = (params: SupplierPayload['supplier']) => {
    const { name: companyName, description, contact } = params || {};
    setValue('companyName', companyName || '');
    setValue('description', description || '');

    const {
      name,
      city,
      state,
      email,
      country,
      phoneNo,
      zipCode,
      address1,
      address2,
      designation,
      countryCode,
    } = contact || {};
    setValue('city', city || '');
    setValue('name', name || '');
    setValue('email', email || '');
    setValue('phoneNo', phoneNo || '');
    setValue('zipCode', zipCode || '');
    setValue('address1', address1 || '');
    setValue('address2', address2 || '');
    setValue('designation', designation || '');
    setValue('state', { name: state || '', value: state || '' });
    setValue('country', { name: country || '', value: countryCode || '' });
  };

  useEffect(() => {
    id && fetchSupplier();
  }, [id, fetchSupplier]);

  const loading = createLoading || getLoading;

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{DUPLICATE_SUPPLIER_TEXT}</Typography>
            <Button variant="contained" color="primary" type="submit" disabled={loading}>
              {DUPLICATE_TEXT}
            </Button>
          </Box>
          <SupplierForm loading={loading} />
        </form>
      </FormProvider>
      <BackdropLoader open={loading} text={SUPPLIER_IS_CREATING_TEXT} />
    </Fragment>
  );
};

export default Duplicate;
