import { Fragment } from 'react';
import {
  OZ_TEXT,
  CC_TEXT,
  MIN_TEXT,
  MAX_TEXT,
  NAME_TEXT,
  MAX_STAFF,
  TYPE_TEXT,
  ROLE_TEXT,
  EMAIL_TEXT,
  IS_CR_TEXT,
  PHONE_TEXT,
  PRICE_TEXT,
  EMPTY_CELL,
  STATUS_TEXT,
  CONTACT_TEXT,
  LOH_COST_TEXT,
  LID_SIZE_TEXT,
  LOAD_ADD_TEXT,
  EXCIPIENT_TEXT,
  PERCENTAGE_TEXT,
  CREATED_BY_TEXT,
  CREATED_AT_TEXT,
  PER_KG_COST_TEXT,
  DESIGNATION_TEXT,
  BOTTLE_SIZE_TEXT,
  TOTAL_STAFF_TEXT,
  COST_PER_KG_TEXT,
  FORMULATION_TEXT,
  COMMON_NAME_TEXT,
  EMPTY_WEIGHT_TEXT,
  ACTIVE_STAFF_TEXT,
  PRODUCT_TYPE_TEXT,
  COMPANY_NAME_TEXT,
  JOINING_DATE_TEXT,
  ORGANIZATION_TEXT,
  LOAD_ADD_UNIT_TEXT,
  CONVERSION_COST_TEXT,
  EXCIPIENT_LABEL_TEXT,
  SUB_PRODUCT_TYPE_TEXT,
  PRIMARY_CATEGORY_TEXT,
  SECONDARY_CATEGORY_TEXT,
  INNER_CAPSULE_TABLET_TEXT,
  INNER_COMPONENT_COST_TEXT,
  PRODUCT_SIZE_TEXT,
  SERVING_SIZE_TEXT,
  FILING_PERCENTAGE_TEXT,
  IS_CHILD_RESISTANT_TEXT,
  SUPPLIERS_TEXT,
  GUMMY_WEIGHT_TEXT,
  NATURE_TEXT,
  SIZE_TEXT,
  MG_PER_SERVING_TEXT,
  PER_SERVING_COST_TEXT,
  PRODUCT_FORMULATION_TEXT,
  SWEETENER_COMPONENT_TEXT,
  QT_PER_MG_TEXT,
  SCOPE_TEXT,
} from 'constants/index';
import { TableHeaderType } from 'interfaces';
import { FormulaTabType } from 'interfaces/TabTypes';
import { InfoTooltip } from 'components/common/InfoTooltip';

export const USER_TABLE_HEADER = [
  NAME_TEXT,
  ORGANIZATION_TEXT,
  EMAIL_TEXT,
  CONTACT_TEXT,
  MAX_STAFF,
  TOTAL_STAFF_TEXT,
  ACTIVE_STAFF_TEXT,
  JOINING_DATE_TEXT,
  '',
];

export const INGREDIENT_TABLE_HEADER = [
  COMMON_NAME_TEXT,
  PRIMARY_CATEGORY_TEXT,
  SECONDARY_CATEGORY_TEXT,
  COST_PER_KG_TEXT,
  '',
];

export const FORMULA_TABLE_HEADER = (activeTab: FormulaTabType) => {
  return [
    NAME_TEXT,
    TYPE_TEXT,
    `${FORMULATION_TEXT} ${TYPE_TEXT}`,
    activeTab === 'all' ? STATUS_TEXT : EMPTY_CELL,
    CREATED_BY_TEXT,
    CREATED_AT_TEXT,
    '',
  ];
};

export const STAFF_TABLE_HEADER = [
  NAME_TEXT,
  ROLE_TEXT,
  EMAIL_TEXT,
  PHONE_TEXT,
  JOINING_DATE_TEXT,
  STATUS_TEXT,
  '',
];
const SUPPLIER_TABLE_HEADER = [COMPANY_NAME_TEXT, NAME_TEXT, DESIGNATION_TEXT];
export const SUPER_SUPPLIER_TABLE_HEADER = [...SUPPLIER_TABLE_HEADER, ''];
export const ADMIN_SUPPLIER_TABLE_HEADER = [...SUPPLIER_TABLE_HEADER, SCOPE_TEXT, ''];
export const SUB_PRODUCT_TYPE_TABLE_HEADER = [NAME_TEXT, PRODUCT_TYPE_TEXT, STATUS_TEXT, ''];
export const PRODUCT_TYPE_TABLE_HEADER = [NAME_TEXT, TYPE_TEXT, STATUS_TEXT, ''];
export const EXCIPIENT_TABLE_HEADER = [NAME_TEXT, STATUS_TEXT, ''];
export const EXCIPIENT_LABEL_TABLE_HEADER = [NAME_TEXT, STATUS_TEXT, ''];
export const EXCIPIENT_TYPE_TABLE_HEADER = [
  NAME_TEXT,
  PRODUCT_TYPE_TEXT,
  EXCIPIENT_LABEL_TEXT,
  PER_KG_COST_TEXT,
  LOAD_ADD_TEXT,
  LOAD_ADD_UNIT_TEXT,
  STATUS_TEXT,
  '',
];

export const PRODUCT_EXCIPIENT_TABLE_HEADER = [
  EXCIPIENT_TEXT,
  PER_KG_COST_TEXT,
  PERCENTAGE_TEXT,
  SUPPLIERS_TEXT,
  '',
];
export const SWEETENER_ELEMENT_TABLE_HEADER = [SWEETENER_COMPONENT_TEXT, QT_PER_MG_TEXT, ''];

export const PRODUCT_SIZE_TABLE_HEADER = [
  NAME_TEXT,
  PRODUCT_TYPE_TEXT,
  SUB_PRODUCT_TYPE_TEXT,
  EMPTY_WEIGHT_TEXT,
  GUMMY_WEIGHT_TEXT,
  MIN_TEXT,
  MAX_TEXT,
  STATUS_TEXT,
  '',
];
export const PRODUCT_FORMULATION_TYPE_TABLE_HEADER = [
  NAME_TEXT,
  PRODUCT_TYPE_TEXT,
  SUB_PRODUCT_TYPE_TEXT,
  LOH_COST_TEXT,
  CONVERSION_COST_TEXT,
  INNER_COMPONENT_COST_TEXT,
  INNER_CAPSULE_TABLET_TEXT,
  STATUS_TEXT,
  '',
];

export const BOTTLE_SIZE_TABLE_HEADER = [
  NAME_TEXT,
  PRODUCT_TYPE_TEXT,
  LID_SIZE_TEXT,
  CC_TEXT,
  OZ_TEXT,
  // GRAMS_TEXT,
  // PRODUCT_TYPE_TEXT,
  STATUS_TEXT,
  '',
];

const InfoTooltipComponent = () => (
  <Fragment>
    {IS_CR_TEXT}
    <span style={{ marginLeft: 5, position: 'relative', top: 6 }}>
      <InfoTooltip title={IS_CHILD_RESISTANT_TEXT} />
    </span>
  </Fragment>
);

export const BOTTLE_PRICE_TABLE_HEADER: TableHeaderType[] = [
  BOTTLE_SIZE_TEXT,
  TYPE_TEXT,
  PRICE_TEXT,
  <InfoTooltipComponent />,
  SUPPLIERS_TEXT,
  STATUS_TEXT,
  '',
];

export const BOTTLE_CAPACITY_TABLE_HEADER = [
  PRODUCT_TYPE_TEXT,
  PRODUCT_SIZE_TEXT,
  BOTTLE_SIZE_TEXT,
  SERVING_SIZE_TEXT,
  LOH_COST_TEXT,
  FILING_PERCENTAGE_TEXT,
  STATUS_TEXT,
  '',
];

export const SWEETENER_TABLE_HEADER = [
  NAME_TEXT,
  NATURE_TEXT,
  SIZE_TEXT,
  TYPE_TEXT,
  PRODUCT_TYPE_TEXT,
  PRODUCT_SIZE_TEXT,
  PRODUCT_FORMULATION_TEXT,
  MG_PER_SERVING_TEXT,
  PER_SERVING_COST_TEXT,
  STATUS_TEXT,
  '',
];

export const SWEETENER_COMPONENT_TABLE_HEADER = [NAME_TEXT, COST_PER_KG_TEXT, STATUS_TEXT, ''];
