import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import BottleCapacityForm from './components/BottleCapacityForm';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  BottleCapacityPayload,
  useGetBottleCapacityLazyQuery,
  useUpdateBottleCapacityMutation,
} from 'generated/graphql';
import {
  HTTP_STATUS,
  BOTTLE_CAPACITY_IS_UPDATED_TEXT,
  bottleCapacityInitialValue,
  EDIT_BOTTLE_CAPACITY_TEXT,
} from 'constants/index';
import { AddBottleCapacityFormType, ParamType } from 'interfaces';
import { updateBottleCapacityTypeSchema } from 'validationSchema';

const Edit: FC = (): JSX.Element => {
  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const bottleCapacityMethods = useForm<AddBottleCapacityFormType>({
    defaultValues: bottleCapacityInitialValue,
    resolver: yupResolver(updateBottleCapacityTypeSchema),
  });

  const { handleSubmit: handleBottleCapacitySubmit, setValue: setBottleCapacityValue } =
    bottleCapacityMethods;

  const [updateBottleCapacity, { loading: productTypeLoading }] = useUpdateBottleCapacityMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateBottleCapacity } = data;
      const { response } = updateBottleCapacity || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getBottleCapacity, { loading: getLoading }] = useGetBottleCapacityLazyQuery({
    onCompleted: (data) => {
      const { getBottleCapacity } = data || {};
      const { response, bottleCapacity } = getBottleCapacity || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(bottleCapacity as BottleCapacityPayload['bottleCapacity']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });
  /**
   * Datas on supplier submit
   * @param data
  /**
   * Datas on bottle price submit
   * @param data 
   */
  const onBottlePriceSubmit: SubmitHandler<AddBottleCapacityFormType> = async (data) => {
    const { fillingPercentage, lohCost, bottleSize, productSize, productType, servingSize } = data || {};
    const { value: bottleSizeId } = bottleSize || {};
    const { value: productSizeId } = productSize || {};
    const { value: productTypeId } = productType || {};
    const { value: servingSizeId } = servingSize || {};

    await updateBottleCapacity({
      variables: {
        updateBottleCapacityInput: {
          id: id || '',
          lohCost: parseFloat(lohCost) || 0,
          bottleSizeId: bottleSizeId || '',
          productSizeId: productSizeId || '',
          productTypeId: productTypeId || '',
          fillPercentage: parseFloat(fillingPercentage) || 0,
          servingSizeId: servingSizeId ? servingSizeId : null,
        },
      },
    });
  };

  const fetchBottlePrice = useCallback(async () => {
    id &&
      (await getBottleCapacity({
        variables: {
          findBottleCapacityInput: {
            id,
          },
        },
      }));
  }, [id, getBottleCapacity]);

  const setValues = (params: BottleCapacityPayload['bottleCapacity']) => {
    const { lohCost, fillPercentage, bottleSize, productType, productSize, servingSize } = params || {};
    const { name: bottleSizeName, id } = bottleSize || {};
    const { name: productTypeName, id: productTypeId } = productType || {};
    const { name: productSizeName, id: productSizeId } = productSize || {};
    const { size, id: servingSizeId } = servingSize || {};
    setBottleCapacityValue('lohCost', `${lohCost}` || '0');
    setBottleCapacityValue('fillingPercentage', `${fillPercentage}` || '0');
    setBottleCapacityValue('bottleSize', {
      value: id ?? '',
      name: bottleSizeName ?? '',
    });
    setBottleCapacityValue('productSize', {
      value: productSizeId ?? '',
      name: productSizeName ?? '',
    });
    setBottleCapacityValue('productType', {
      value: productTypeId ?? '',
      name: productTypeName ?? '',
    });
    setBottleCapacityValue('servingSize', {
      value: servingSizeId ?? '',
      name: `${size}` ?? '',
    });
  };

  useEffect(() => {
    id && fetchBottlePrice();
  }, [id, fetchBottlePrice]);

  const loading = productTypeLoading || getLoading;

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{EDIT_BOTTLE_CAPACITY_TEXT}</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={6} md={12}>
          <FormProvider {...bottleCapacityMethods}>
            <form onSubmit={handleBottleCapacitySubmit(onBottlePriceSubmit)}>
              <BottleCapacityForm loading={loading} isEdit />
            </form>
          </FormProvider>
        </Grid>
      </Grid>

      <BackdropLoader open={loading} text={BOTTLE_CAPACITY_IS_UPDATED_TEXT} />
    </Fragment>
  );
};

export default Edit;
