// components
import Users from 'pages/Users';
import Staff from 'pages/Staff';
import Formulas from 'pages/Formulas';
import Dashboard from 'pages/Dashboard';
import Ingredients from 'pages/Ingredients';
import Profile from 'components/main/Profile';
import Settings from 'components/main/Settings';
import AddUser from 'components/main/Users/Add';
import AddStaff from 'components/main/Staff/Add';
import RequestSent from 'pages/auth/RequestSent';
import EditUser from 'components/main/Users/Edit';
import EditStaff from 'components/main/Staff/Edit';
import FormulaDetailPage from 'pages/FormulaDetail';
import ResetPassword from 'pages/auth/ResetPassword';
import ForgetPassword from 'pages/auth/ForgetPassword';
import AddFormulaByDosage from 'pages/FormulaByDosage';
import AddFormulaByFactBox from 'pages/FormulaByFactBox';
import AddIngredient from 'components/main/Ingredients/Add';
import EditIngredient from 'components/main/Ingredients/Edit';
import EditFormula from 'components/main/Formulas/Dosage/Edit';
import SetPasswordSuccess from 'pages/auth/SetPasswordSuccess';
import AddFormulaByIngredients from 'pages/FormulaByIngredients';
import DuplicateFormula from 'components/main/Formulas/Dosage/Duplicate';

// supplier pages
import Supplier from 'pages/Supplier';
import AddSupplier from 'pages/Supplier/Add';
import EditSupplier from 'pages/Supplier/Edit';
import DuplicateSupplier from 'pages/Supplier/Duplicate';

// guards
import { AdminGuard } from './Guards/AdminGuard';
import { SuperOrAdminGuard } from './Guards/SuperOrAdminGuard';
import { AdminOrStaffGuard } from './Guards/AdminOrStaffGuard';
import { SuperAdminGuard } from 'routes/Guards/SuperAdminGuard';
import { SuperOrAdminOrStaffGuard } from './Guards/SuperOrAdminOrStaffGuard';

// routes, interfaces
import {
  AUTH_LINKS,
  USERS_ROUTE,
  STAFF_ROUTE,
  PROFILE_ROUTE,
  FORMULA_ROUTE,
  SETTINGS_ROUTE,
  ADD_USER_ROUTE,
  DASHBOARD_ROUTE,
  ADD_STAFF_ROUTE,
  INGREDIENTS_ROUTE,
  ADD_INGREDIENT_ROUTE,
  EDIT_USER_WITH_ID_ROUTE,
  EDIT_STAFF_WITH_ID_ROUTE,
  EDIT_FORMULA_WITH_ID_ROUTE,
  ADD_FORMULA_BY_DOSAGE_ROUTE,
  EDIT_INGREDIENT_WITH_ID_ROUTE,
  FORMULA_DETAIL_ROUTE_WITH_ID,
  ADD_FORMULA_BY_INGREDIENTS_ROUTE,
  ADD_FORMULA_BY_FACT_BOX_ROUTE,
  DUPLICATE_FORMULA_WITH_ID_ROUTE,
  SUPPLIER_ROUTE,
  ADD_SUPPLIER_ROUTE,
  EDIT_SUPPLIER_WITH_ID_ROUTE,
  PRODUCT_TYPE_ROUTE,
  EDIT_PRODUCT_TYPE_WITH_ID_ROUTE,
  SUB_PRODUCT_TYPE_ROUTE,
  EDIT_SUB_PRODUCT_TYPE_WITH_ID_ROUTE,
  PRODUCT_FORMULATION_ROUTE,
  EDIT_PRODUCT_FORMULATION_WITH_ID_ROUTE,
  ADD_SUB_PRODUCT_TYPE_ROUTE,
  PRODUCT_SIZE_ROUTE,
  EDIT_PRODUCT_SIZE_WITH_ID_ROUTE,
  EXCIPIENT_ROUTE,
  EDIT_EXCIPIENT_WITH_ID_ROUTE,
  EXCIPIENT_LABEL_ROUTE,
  EDIT_EXCIPIENT_LABEL_WITH_ID_ROUTE,
  EXCIPIENT_TYPES_ROUTE,
  EDIT_EXCIPIENT_TYPES_WITH_ID_ROUTE,
  PRODUCT_EXCIPIENT_WITH_ID_ROUTE,
  BOTTLE_SIZE_ROUTE,
  EDIT_BOTTLE_SIZE_WITH_ID_ROUTE,
  BOTTLE_PRICE_ROUTE,
  EDIT_BOTTLE_PRICE_WITH_ID_ROUTE,
  BOTTLE_CAPACITY_ROUTE,
  EDIT_BOTTLE_CAPACITY_WITH_ID_ROUTE,
  SWEETENER_ROUTE,
  EDIT_SWEETENER_WITH_ID_ROUTE,
  SWEETENER_COMPONENT_ROUTE,
  ADD_SWEETENER_COMPONENT_ROUTE,
  EDIT_SWEETENER_COMPONENT_WITH_ID_ROUTE,
  SWEETENER_ELEMENT_WITH_ID_ROUTE,
  ADD_BOTTLE_SIZE_ROUTE,
  ADD_PRODUCT_SIZE_ROUTE,
  ADD_SWEETENER_ROUTE,
  DUPLICATE_SUPPLIER_WITH_ID_ROUTE,
  ADD_EXCIPIENT_ROUTE,
  ADD_EXCIPIENT_LABEL_ROUTE,
  ADD_EXCIPIENT_TYPES_ROUTE,
} from 'constants/index';

//interface
import { AppRoutesType } from 'interfaces';

// product type pages
import ProductType from 'pages/ProductType';
import EditProductType from 'pages/ProductType/Edit';

// sub product type pages
import SubProductType from 'pages/SubProductType';
import EditSubProductType from 'pages/SubProductType/Edit';
import AddSubProductType from 'pages/SubProductType/Add';

// product formulation type pages
import ProductFormulationType from 'pages/ProductFormulationType';
import EditProductFormulationType from 'pages/ProductFormulationType/Edit';

// product size pages
import ProductSize from 'pages/ProductSize';
import AddProductSize from 'pages/ProductSize/Add';
import EditProductSize from 'pages/ProductSize/Edit';

// excipient pages
import Excipient from 'pages/Excipient';
import AddExcipient from 'pages/Excipient/Add';
import EditExcipient from 'pages/Excipient/Edit';

// excipient label pages
import ExcipientLabel from 'pages/ExcipientLabel';
import AddExcipientLabel from 'pages/ExcipientLabel/Add';
import EditExcipientLabel from 'pages/ExcipientLabel/Edit';

// excipient type pages
import ExcipientType from 'pages/ExcipientType';
import AddExcipientType from 'pages/ExcipientType/Add';
import EditExcipientType from 'pages/ExcipientType/Edit';

// product excipient pages
import ProductExcipient from 'pages/ProductExcipient';

// bottle size pages
import BottleSize from 'pages/BottleSize';
import AddBottleSize from 'pages/BottleSize/Add';
import EditBottleSize from 'pages/BottleSize/Edit';

// bottle Price pages
import BottlePrice from 'pages/BottlePrice';
import EditBottlePrice from 'pages/BottlePrice/Edit';

// bottle capacity pages
import BottleCapacity from 'pages/BottleCapacity';
import EditBottleCapacity from 'pages/BottleCapacity/Edit';

// sweetener pages
import SweetenerSystem from 'pages/SweetenerSystem';
import AddSweetenerSystems from 'pages/SweetenerSystem/Add';
import EditSweetenerSystem from 'pages/SweetenerSystem/Edit';

// sweetener element pages
import SweetenerElement from 'pages/SweetenerElements';
// sweetener component pages
import SweetenerComponent from 'pages/SweetenerComponent';
import AddSweetenerComponent from 'pages/SweetenerComponent/Add';
import EditSweetenerComponent from 'pages/SweetenerComponent/Edit';

export const appRoutes: AppRoutesType[] = [
  // Super Admin Private Routes

  // User Routes Start
  {
    id: 1,
    url: USERS_ROUTE,
    element: (
      <SuperAdminGuard>
        <Users />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 2,
    url: ADD_USER_ROUTE,
    element: (
      <SuperAdminGuard>
        <AddUser />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 3,
    url: EDIT_USER_WITH_ID_ROUTE,
    element: (
      <SuperAdminGuard>
        <EditUser />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  // User Routes End

  // Supplier routes start

  {
    id: 7,
    url: SUPPLIER_ROUTE,
    element: (
      <SuperOrAdminGuard>
        <Supplier />
      </SuperOrAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 8,
    url: ADD_SUPPLIER_ROUTE,
    element: (
      <SuperOrAdminGuard>
        <AddSupplier />
      </SuperOrAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 9,
    url: EDIT_SUPPLIER_WITH_ID_ROUTE,
    element: (
      <SuperOrAdminGuard>
        <EditSupplier />
      </SuperOrAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 91,
    url: DUPLICATE_SUPPLIER_WITH_ID_ROUTE,
    element: (
      <AdminGuard>
        <DuplicateSupplier />
      </AdminGuard>
    ),
    isPrivate: true,
  },

  // Supplier routes end

  // Product Type routes start

  {
    id: 9,
    url: PRODUCT_TYPE_ROUTE,
    element: (
      <SuperAdminGuard>
        <ProductType />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 10,
    url: EDIT_PRODUCT_TYPE_WITH_ID_ROUTE,
    element: (
      <SuperAdminGuard>
        <EditProductType />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  // Product Type routes end

  // Sub Product Type routes start

  {
    id: 11,
    url: ADD_SUB_PRODUCT_TYPE_ROUTE,
    element: (
      <SuperAdminGuard>
        <AddSubProductType />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 12,
    url: SUB_PRODUCT_TYPE_ROUTE,
    element: (
      <SuperAdminGuard>
        <SubProductType />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 13,
    url: EDIT_SUB_PRODUCT_TYPE_WITH_ID_ROUTE,
    element: (
      <SuperAdminGuard>
        <EditSubProductType />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  // Sub Product Type routes end

  // Product Size routes start
  {
    id: 14,
    url: PRODUCT_SIZE_ROUTE,
    element: (
      <SuperAdminGuard>
        <ProductSize />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 141,
    url: ADD_PRODUCT_SIZE_ROUTE,
    element: (
      <SuperAdminGuard>
        <AddProductSize />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 15,
    url: EDIT_PRODUCT_SIZE_WITH_ID_ROUTE,
    element: (
      <SuperAdminGuard>
        <EditProductSize />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  // Product Size routes end

  // Product Formulation routes start
  {
    id: 16,
    url: PRODUCT_FORMULATION_ROUTE,
    element: (
      <SuperAdminGuard>
        <ProductFormulationType />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 17,
    url: EDIT_PRODUCT_FORMULATION_WITH_ID_ROUTE,
    element: (
      <SuperAdminGuard>
        <EditProductFormulationType />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  // Product Formulation routes end

  // Excipient routes start
  {
    id: 18,
    url: EXCIPIENT_ROUTE,
    element: (
      <SuperAdminGuard>
        <Excipient />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 19,
    url: EDIT_EXCIPIENT_WITH_ID_ROUTE,
    element: (
      <SuperAdminGuard>
        <EditExcipient />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 'excipient-1',
    url: ADD_EXCIPIENT_ROUTE,
    element: (
      <SuperAdminGuard>
        <AddExcipient />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  // Excipient routes end

  // Excipient Label routes start

  {
    id: 20,
    url: EXCIPIENT_LABEL_ROUTE,
    element: (
      <SuperAdminGuard>
        <ExcipientLabel />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 21,
    url: EDIT_EXCIPIENT_LABEL_WITH_ID_ROUTE,
    element: (
      <SuperAdminGuard>
        <EditExcipientLabel />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  {
    id: 'excipient-label-12',
    url: ADD_EXCIPIENT_LABEL_ROUTE,
    element: (
      <SuperAdminGuard>
        <AddExcipientLabel />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  // Excipient Label routes end

  // Excipient Type routes start

  {
    id: 22,
    url: EXCIPIENT_TYPES_ROUTE,
    element: (
      <SuperAdminGuard>
        <ExcipientType />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 23,
    url: EDIT_EXCIPIENT_TYPES_WITH_ID_ROUTE,
    element: (
      <SuperAdminGuard>
        <EditExcipientType />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  {
    id: 'excipient-type-12',
    url: ADD_EXCIPIENT_TYPES_ROUTE,
    element: (
      <SuperAdminGuard>
        <AddExcipientType />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  // Excipient Type routes end

  // Product Excipient  routes start

  {
    id: 24,
    url: PRODUCT_EXCIPIENT_WITH_ID_ROUTE,
    element: (
      <SuperAdminGuard>
        <ProductExcipient />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  // Product Excipient  routes end

  // Bottle Size routes start
  {
    id: 26,
    url: BOTTLE_SIZE_ROUTE,
    element: (
      <SuperAdminGuard>
        <BottleSize />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 27,
    url: EDIT_BOTTLE_SIZE_WITH_ID_ROUTE,
    element: (
      <SuperAdminGuard>
        <EditBottleSize />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  {
    id: 271,
    url: ADD_BOTTLE_SIZE_ROUTE,
    element: (
      <SuperAdminGuard>
        <AddBottleSize />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  // Bottle Size routes end

  // Bottle Price routes start
  {
    id: 28,
    url: BOTTLE_PRICE_ROUTE,
    element: (
      <SuperAdminGuard>
        <BottlePrice />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 29,
    url: EDIT_BOTTLE_PRICE_WITH_ID_ROUTE,
    element: (
      <SuperAdminGuard>
        <EditBottlePrice />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  // Bottle Price routes end

  // Bottle Capacity routes start

  {
    id: 30,
    url: BOTTLE_CAPACITY_ROUTE,
    element: (
      <SuperAdminGuard>
        <BottleCapacity />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 31,
    url: EDIT_BOTTLE_CAPACITY_WITH_ID_ROUTE,
    element: (
      <SuperAdminGuard>
        <EditBottleCapacity />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  // Bottle Capacity routes end

  // Sweetener routes start
  {
    id: 32,
    url: SWEETENER_ROUTE,
    element: (
      <SuperAdminGuard>
        <SweetenerSystem />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 33,
    url: ADD_SWEETENER_ROUTE,
    element: (
      <SuperAdminGuard>
        <AddSweetenerSystems />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 34,
    url: EDIT_SWEETENER_WITH_ID_ROUTE,
    element: (
      <SuperAdminGuard>
        <EditSweetenerSystem />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  // Sweetener routes end

  //Sweetener Elements routes

  {
    id: 35,
    url: SWEETENER_ELEMENT_WITH_ID_ROUTE,
    element: (
      <SuperAdminGuard>
        <SweetenerElement />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  // Sweetener Elements routes end

  // Sweetener Component routes start
  {
    id: 36,
    url: SWEETENER_COMPONENT_ROUTE,
    element: (
      <SuperAdminGuard>
        <SweetenerComponent />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 37,
    url: ADD_SWEETENER_COMPONENT_ROUTE,
    element: (
      <SuperAdminGuard>
        <AddSweetenerComponent />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 38,
    url: EDIT_SWEETENER_COMPONENT_WITH_ID_ROUTE,
    element: (
      <SuperAdminGuard>
        <EditSweetenerComponent />
      </SuperAdminGuard>
    ),
    isPrivate: true,
  },

  // Sweetener Component routes end

  // Admin and Staff Private Routes

  // Formula Routes Start
  {
    id: 4,
    url: FORMULA_ROUTE,
    element: (
      <AdminOrStaffGuard>
        <Formulas />
      </AdminOrStaffGuard>
    ),
    isPrivate: true,
  },
  {
    id: 5,
    url: ADD_FORMULA_BY_DOSAGE_ROUTE,
    element: (
      <AdminOrStaffGuard>
        <AddFormulaByDosage />
      </AdminOrStaffGuard>
    ),
    isPrivate: true,
  },
  {
    id: 21,
    url: ADD_FORMULA_BY_INGREDIENTS_ROUTE,
    element: (
      <AdminOrStaffGuard>
        <AddFormulaByIngredients />
      </AdminOrStaffGuard>
    ),
    isPrivate: true,
  },

  {
    id: 22,
    url: ADD_FORMULA_BY_FACT_BOX_ROUTE,
    element: (
      <AdminOrStaffGuard>
        <AddFormulaByFactBox />
      </AdminOrStaffGuard>
    ),
    isPrivate: true,
  },

  {
    id: 6,
    url: EDIT_FORMULA_WITH_ID_ROUTE,
    element: (
      <AdminOrStaffGuard>
        <EditFormula />
      </AdminOrStaffGuard>
    ),
    isPrivate: true,
  },

  {
    id: 23,
    url: DUPLICATE_FORMULA_WITH_ID_ROUTE,
    element: (
      <AdminOrStaffGuard>
        <DuplicateFormula />
      </AdminOrStaffGuard>
    ),
    isPrivate: true,
  },

  {
    id: 20,
    url: FORMULA_DETAIL_ROUTE_WITH_ID,
    element: (
      <AdminOrStaffGuard>
        <FormulaDetailPage />
      </AdminOrStaffGuard>
    ),
    isPrivate: true,
  },

  // Formula Routes End

  // Staff Routes Start
  {
    id: 7,
    url: STAFF_ROUTE,
    element: (
      <AdminGuard>
        <Staff />
      </AdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 8,
    url: ADD_STAFF_ROUTE,
    element: (
      <AdminGuard>
        <AddStaff />
      </AdminGuard>
    ),
    isPrivate: true,
  },
  {
    id: 9,
    url: EDIT_STAFF_WITH_ID_ROUTE,
    element: (
      <AdminGuard>
        <EditStaff />
      </AdminGuard>
    ),
    isPrivate: true,
  },

  // Staff Routes End

  // Common Private Routes

  // Dashboard Route
  {
    id: 10,
    url: DASHBOARD_ROUTE,
    element: (
      <SuperOrAdminOrStaffGuard>
        <Dashboard />
      </SuperOrAdminOrStaffGuard>
    ),
    isPrivate: true,
  },

  // Profile Route
  {
    id: 11,
    url: PROFILE_ROUTE,
    element: (
      <SuperOrAdminOrStaffGuard>
        <Profile />
      </SuperOrAdminOrStaffGuard>
    ),
    isPrivate: true,
  },

  // Setting Route
  {
    id: 12,
    url: SETTINGS_ROUTE,
    element: (
      <SuperOrAdminOrStaffGuard>
        <Settings />
      </SuperOrAdminOrStaffGuard>
    ),
    isPrivate: true,
  },

  // Ingredient Routes Start
  {
    id: 13,
    url: INGREDIENTS_ROUTE,
    element: (
      <SuperOrAdminOrStaffGuard>
        <Ingredients />
      </SuperOrAdminOrStaffGuard>
    ),
    isPrivate: true,
  },
  {
    id: 14,
    url: ADD_INGREDIENT_ROUTE,
    element: (
      <SuperOrAdminOrStaffGuard>
        <AddIngredient />
      </SuperOrAdminOrStaffGuard>
    ),
    isPrivate: true,
  },
  {
    id: 15,
    url: EDIT_INGREDIENT_WITH_ID_ROUTE,
    element: (
      <SuperOrAdminOrStaffGuard>
        <EditIngredient />
      </SuperOrAdminOrStaffGuard>
    ),
    isPrivate: true,
  },

  // Ingredient Routes End

  // public routes
  {
    id: 16,

    url: AUTH_LINKS.FORGOT_PASSWORD_LINK,
    element: <ForgetPassword />,
    isPrivate: false,
  },
  {
    id: 17,
    url: AUTH_LINKS.REQUEST_SENT_SUCCESSFULLY,
    element: <RequestSent />,
    isPrivate: false,
  },
  {
    id: 18,
    url: AUTH_LINKS.SET_PASSWORD_LINK,
    element: <ResetPassword />,
    isPrivate: false,
  },
  {
    id: 19,
    url: AUTH_LINKS.PASSWORD_SUCCESS,
    element: <SetPasswordSuccess />,
    isPrivate: false,
  },
];
