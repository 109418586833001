import { FC, Fragment, useContext } from 'react';
import { Card, Typography } from '@mui/material';
// components
import RowView from 'components/common/RowView';
import DataLoader from 'components/common/DataLoader';
// constants, context, graphql
import {
  INFO_TEXT,
  FORM_TEXT,
  VEGAN_TEXT,
  COATING_TEXT,
  FACT_BOX_TEXT,
  BEADLETS_TEXT,
  EXCIPIENT_TEXT,
  BOTTLE_CAP_TEXT,
  FLAVOR_TYPE_TEXT,
  PROTEIN_BASE_TEXT,
  SAND_COATING_TEXT,
  PRODUCT_SIZE_TEXT,
  TABLET_COLOR_TEXT,
  PRODUCT_TYPE_TEXT,
  FLAVOR_SYSTEM_TEXT,
  SWEETENER_TYPE_TEXT,
  DESIRED_SERVING_SIZE,
  EXCIPIENT_LABEL_TEXT,
  SERVING_PER_CONTAINER,
  SWEETENER_SYSTEM_TEXT,
  PRODUCT_FORMULATION_TEXT,
  OUTER_CAPSULE_POLYMER_TEXT,
  INNER_CAPSULE_POLYMER_TEXT,
  TYPE_OF_CAPSULE_POLYMER_TEXT,
  MINI_TABLETS_FORMULATION_TEXT,
} from 'constants/index';
import { formatValue } from 'lib/helper';
import { FormulaDetailContext } from 'contexts/FormulaDetailContext';
import { FormulaCapsuleTypeEnum, SweetenerType, TabletCoatingType } from 'generated/graphql';

const FormulaInfoCard: FC = () => {
  const { state, loading } = useContext(FormulaDetailContext);
  const { formula } = state;
  const {
    beadlet,
    bottleCap,
    veganType,
    productType,
    servingSize,
    proteinBase,
    factBoxType,
    tabletCoating,
    excipientType,
    subProductType,
    servingContainer,
    formulaSweeteners,
    miniTabFormulation,
    formulaCapsuleTypes,
    formulaTabletCoatings,
    productFormulationType,
    sandCoating,
    productSize,
  } = formula || {};

  const { name } = productType || {};
  const { name: formName } = subProductType || {};
  const { name: productFormulationTypeName } = productFormulationType || {};

  const { name: beadletName } = beadlet || {};
  const { name: tabletCoatingName } = tabletCoating || {};

  const { name: excipientTypeName, excipientLabel } = excipientType || {};
  const { name: excipientLabelName } = excipientLabel || {};

  const { name: sandCoatingName } = sandCoating || {};
  const { name: productSizeName } = productSize || {};

  // capsule Types
  const capsuleType = formulaCapsuleTypes?.find((item) => item?.type === FormulaCapsuleTypeEnum.Polymer);

  const innerCapsuleType = formulaCapsuleTypes?.find(
    (item) => item?.type === FormulaCapsuleTypeEnum.InnerPolymer,
  );

  const outerCapsuleType = formulaCapsuleTypes?.find(
    (item) => item?.type === FormulaCapsuleTypeEnum.OuterPolymer,
  );

  // Tablet Coating
  const singleLayer = formulaTabletCoatings?.find((item) => {
    return item?.type === TabletCoatingType.SingleLayered;
  });

  const biLayer = formulaTabletCoatings?.find((item) => {
    return item?.type === TabletCoatingType.BiLayered;
  });

  const triLayer = formulaTabletCoatings?.find((item) => {
    return item?.type === TabletCoatingType.TriLayered;
  });

  // sweetener type

  const flavorType = formulaSweeteners?.find((item) => {
    return item?.type === SweetenerType.Flavor;
  });

  const { nature, sweetener } = flavorType || {};
  const { size: flavorSweetenerSize } = sweetener || {};

  const sweetenerType = formulaSweeteners?.find((item) => {
    return item?.type === SweetenerType.Sweetener;
  });

  const { nature: sweetenerNature, sweetener: sweetenerObj } = sweetenerType || {};
  const { size: sweetenerObjSize } = sweetenerObj || {};

  return (
    <Card>
      <Typography variant="h6">{INFO_TEXT}</Typography>

      {loading ? (
        <DataLoader columns={12} rows={6} />
      ) : (
        <Fragment>
          <RowView name={PRODUCT_TYPE_TEXT} value={name ?? ''} />

          <RowView name={FORM_TEXT} value={formName ?? ''} />

          <RowView name={PRODUCT_FORMULATION_TEXT} value={productFormulationTypeName ?? ''} />

          <RowView name={DESIRED_SERVING_SIZE} value={`${servingSize}` ?? ''} />

          <RowView name={SERVING_PER_CONTAINER} value={`${servingContainer}` ?? '--'} />

          <RowView name={BOTTLE_CAP_TEXT} value={formatValue(bottleCap ?? '')} />

          <RowView name={FACT_BOX_TEXT} value={factBoxType ?? ''} />

          <RowView name={PROTEIN_BASE_TEXT} value={proteinBase ?? ''} />

          <RowView name={VEGAN_TEXT} value={veganType ?? ''} />
          <RowView name={SAND_COATING_TEXT} value={sandCoatingName ?? ''} />

          <RowView name={BEADLETS_TEXT} value={beadletName ?? ''} />

          <RowView name={COATING_TEXT} value={tabletCoatingName ?? ''} />

          <RowView name={`Layer 1 ${TABLET_COLOR_TEXT}`} value={singleLayer?.coatingColor ?? ''} />

          <RowView name={`Layer 2 ${TABLET_COLOR_TEXT}`} value={biLayer?.coatingColor ?? ''} />

          <RowView name={`Layer 3 ${TABLET_COLOR_TEXT}`} value={triLayer?.coatingColor ?? ''} />

          <RowView name={EXCIPIENT_LABEL_TEXT} value={excipientLabelName ?? ''} />
          <RowView name={EXCIPIENT_TEXT} value={excipientTypeName ?? ''} />
          <RowView name={PRODUCT_SIZE_TEXT} value={productSizeName ?? ''} />

          <RowView name={FLAVOR_TYPE_TEXT} value={nature ?? ''} />

          <RowView name={FLAVOR_SYSTEM_TEXT} value={flavorSweetenerSize ? flavorSweetenerSize : ''} />

          <RowView name={SWEETENER_TYPE_TEXT} value={sweetenerNature ?? ''} />

          <RowView name={SWEETENER_SYSTEM_TEXT} value={sweetenerObjSize ? sweetenerObjSize : ''} />

          <RowView name={MINI_TABLETS_FORMULATION_TEXT} value={miniTabFormulation ?? ''} />

          <RowView name={TYPE_OF_CAPSULE_POLYMER_TEXT} value={capsuleType?.capsuleType?.name ?? ''} />

          <RowView name={INNER_CAPSULE_POLYMER_TEXT} value={innerCapsuleType?.capsuleType?.name ?? ''} />

          <RowView name={OUTER_CAPSULE_POLYMER_TEXT} value={outerCapsuleType?.capsuleType?.name ?? ''} />
        </Fragment>
      )}
    </Card>
  );
};

export default FormulaInfoCard;
