import { FC, Fragment } from 'react';
import { Box, Button, Card, CircularProgress, Divider, LinearProgress, Typography } from '@mui/material';
// components
import DataLoader from 'components/common/DataLoader';
import RowView from 'components/common/RowView';
import ColumnForFormulaEstimator from 'components/common/ColumnForFormulaEstimator';
// constants, interface, styles
import {
  LID_TEXT,
  COST_TEXT,
  BOTTLE_TEXT,
  COST_VIA_MFG,
  NO_UPLOAD_TEXT,
  CALCULATE_TEXT,
  DESICCANT_TEXT,
  COST_VIA_CO_MFG,
  RECOMMENDED_TEXT,
  BOTTLE_FILL_TEXT,
  TABLET_SIZE_TEXT,
  CAPSULE_PRICE_TEXT,
  CHILD_RESISTANT_TEXT,
  PER_BOTTLE_COST_TEXT,
  PACKAGE_ESTIMATOR_TEXT,
  OUTER_CAPSULE_PRICE_TEXT,
  INNER_CAPSULE_PRICE_TEXT,
} from 'constants/index';
import { flexCenter } from 'theme/styleConstant';
import { FormulaEstimatorDetailProps } from 'interfaces';
import { flexCenterBetween } from 'styles/commonComponentStyle';

const FormulaEstimatorDetail: FC<FormulaEstimatorDetailProps> = ({
  title,
  heading,
  loading,
  bottleCost,
  bottleFill,
  attachment,
  capsulePrice,
  calculateCost,
  packageEstimator,
  innerCapsulePrice,
  outerCapsulePrice,
  isDetailPage = false,
}) => {
  const { fillPercentage } = bottleFill;
  const { coMfgCost, mfgCost } = bottleCost;
  const { attachmentUrl } = attachment || {};
  const { cc, isChildResistant, lidSize, tabletSize } = packageEstimator;

  const { price, productSize } = capsulePrice || {};
  const { name } = productSize || {};

  const { price: innerPrice, productSize: innerProductSize } = innerCapsulePrice || {};
  const { name: innerName } = innerProductSize || {};

  const { price: outerPrice, productSize: outerProductSize } = outerCapsulePrice || {};
  const { name: outerName } = outerProductSize || {};

  return (
    <Card>
      {loading ? (
        <DataLoader columns={12} rows={1} />
      ) : (
        <Fragment>
          <Box sx={flexCenterBetween}>
            <Box>
              <Typography variant="h6">{heading ?? ''}</Typography>
              <Typography variant="caption" fontSize={12} fontWeight={700}>
                {title}
              </Typography>
            </Box>
            {isDetailPage ? (
              <Fragment />
            ) : (
              <Button variant="outlined" onClick={calculateCost}>
                {CALCULATE_TEXT} {COST_TEXT}
              </Button>
            )}
          </Box>
        </Fragment>
      )}

      {loading ? (
        <Box sx={flexCenter} height={200}>
          <CircularProgress />
        </Box>
      ) : attachmentUrl ? (
        <Box borderRadius={1} pb={1}>
          <img width="100%" height={215} src={attachmentUrl} alt="formula-img" />
        </Box>
      ) : (
        <Box sx={flexCenter} bgcolor={'gray'} width="100%" height={215} borderRadius={1}>
          <Typography fontSize={16}>{NO_UPLOAD_TEXT}</Typography>
        </Box>
      )}
      {loading ? (
        <DataLoader columns={12} rows={1} />
      ) : (
        <Fragment>
          {tabletSize && <RowView isInfo={false} name={TABLET_SIZE_TEXT} value={tabletSize} />}
          {innerPrice && (
            <Fragment>
              <RowView
                isInfo={false}
                name={INNER_CAPSULE_PRICE_TEXT}
                value={`$${innerPrice} (${innerName || '--'}) / 1000`}
              />
            </Fragment>
          )}

          {outerPrice && (
            <Fragment>
              <RowView
                isInfo={false}
                name={OUTER_CAPSULE_PRICE_TEXT}
                value={`$${outerPrice} (${outerName || '--'}) / 1000`}
              />
              <Divider />
            </Fragment>
          )}

          {price && (
            <Fragment>
              <RowView
                isInfo={false}
                name={CAPSULE_PRICE_TEXT}
                value={`$${price} (${name || '--'}) / 1000`}
              />
              <Divider />
            </Fragment>
          )}
        </Fragment>
      )}

      <ColumnForFormulaEstimator title={PER_BOTTLE_COST_TEXT}>
        <RowView
          name={COST_VIA_MFG}
          value={mfgCost ? `$${mfgCost}` : '--'}
          loading={loading}
          isInfo={false}
        />
        <RowView
          name={COST_VIA_CO_MFG}
          value={coMfgCost ? `$${coMfgCost}` : '--'}
          loading={loading}
          isInfo={false}
        />
      </ColumnForFormulaEstimator>

      <Divider />

      <ColumnForFormulaEstimator title={PACKAGE_ESTIMATOR_TEXT}>
        <RowView name={BOTTLE_TEXT} value={cc ? `${cc}cc` : '--'} loading={loading} isInfo={false} />
        <RowView name={LID_TEXT} value={lidSize ? `${lidSize}mm` : '--'} loading={loading} isInfo={false} />
        <RowView
          name={CHILD_RESISTANT_TEXT}
          value={isChildResistant ? 'Y' : 'N'}
          loading={loading}
          isInfo={false}
        />

        <RowView name={DESICCANT_TEXT} value={RECOMMENDED_TEXT} loading={loading} isInfo={false} />
      </ColumnForFormulaEstimator>

      <Divider />

      <ColumnForFormulaEstimator
        title={BOTTLE_FILL_TEXT}
        heading={fillPercentage ? `${fillPercentage}%` : ''}>
        {loading ? (
          <DataLoader columns={12} rows={1} />
        ) : (
          <LinearProgress
            variant="determinate"
            value={fillPercentage ?? 0}
            sx={{
              mt: 2,
            }}
          />
        )}
      </ColumnForFormulaEstimator>
    </Card>
  );
};

export default FormulaEstimatorDetail;
