import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import DataLoader from 'components/common/DataLoader';
import ProductSizeForm from './components/ProductSizeForm';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  ProductSizePayload,
  useGetProductSizeLazyQuery,
  useCreateProductSizeMutation,
  useUpdateProductSizeMutation,
} from 'generated/graphql';
import {
  ADD_TEXT,
  HTTP_STATUS,
  ADD_PRODUCT_SIZE_TEXT,
  EDIT_PRODUCT_SIZE_TEXT,
  productSizeInitialValue,
  PRODUCT_SIZE_IS_UPDATED_TEXT,
  PRODUCT_SIZE_IS_CREATING_TEXT,
} from 'constants/index';
import { updateProductSizeSchema } from 'validationSchema';
import { AddProductSizeFormType, ParamType } from 'interfaces';

const Edit: FC = (): JSX.Element => {
  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const productSizeMethods = useForm<AddProductSizeFormType>({
    defaultValues: productSizeInitialValue,
    resolver: yupResolver(updateProductSizeSchema),
  });

  const { handleSubmit: handleProductSizeSubmit, setValue: setProductSizeValue } = productSizeMethods;

  const [updateProductSize, { loading: productTypeLoading }] = useUpdateProductSizeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateProductSize } = data;
      const { response } = updateProductSize || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [createProductSize, { loading: createLoading }] = useCreateProductSizeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createProductSize } = data;
      const { response } = createProductSize || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getProductSize, { loading: getLoading }] = useGetProductSizeLazyQuery({
    onCompleted: (data) => {
      const { getProductSize } = data || {};
      const { response, productSize } = getProductSize || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(productSize as ProductSizePayload['productSize']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });
  /**
   * Datas on supplier submit
   * @param data
   */
  const onProductSizeSubmit: SubmitHandler<AddProductSizeFormType> = async (data) => {
    const { name, emptyWtMg, max, min, productType, subProductType, gummyWeight } = data || {};
    const { value } = productType || {};
    const { value: subProductTypeId } = subProductType || {};

    if (id) {
      await updateProductSize({
        variables: {
          updateProductSizeInput: {
            id: id || '',
            name,
            min: Number(min),
            max: Number(max),
            productTypeId: value,
            emptyWtMg: Number(emptyWtMg),
            ...(subProductTypeId && { subProductTypeId }),
            gummyWeight: Number(gummyWeight),
          },
        },
      });
    } else {
      await createProductSize({
        variables: {
          createProductSizeInput: {
            name,
            min: Number(min),
            max: Number(max),
            productTypeId: value,
            emptyWtMg: Number(emptyWtMg),
            gummyWeight: Number(gummyWeight),
            ...(subProductTypeId && { subProductTypeId }),
          },
        },
      });
    }
  };

  const fetchProductSize = useCallback(async () => {
    id &&
      (await getProductSize({
        variables: {
          getProductSizeInput: {
            id,
          },
        },
      }));
  }, [id, getProductSize]);

  const setValues = (params: ProductSizePayload['productSize']) => {
    const { name, emptyWtMg, max, min, productType, subProductType, gummyWeight } = params || {};
    const { id, name: productName } = productType || {};
    const { id: subProId, name: subProductName } = subProductType || {};

    setProductSizeValue('name', name || '');
    setProductSizeValue('emptyWtMg', `${emptyWtMg}` || '0');
    setProductSizeValue('min', `${min}` || '0');
    setProductSizeValue('max', `${max}` || '0');
    setProductSizeValue('gummyWeight', `${gummyWeight}` || '0');
    setProductSizeValue('productType', {
      value: id ?? '',
      name: productName ?? '',
    });
    setProductSizeValue('subProductType', {
      value: subProId ?? '',
      name: subProductName ?? '',
    });
  };

  useEffect(() => {
    id && fetchProductSize();
  }, [id, fetchProductSize]);

  const loading = productTypeLoading || createLoading;

  return (
    <Fragment>
      <FormProvider {...productSizeMethods}>
        <form onSubmit={handleProductSizeSubmit(onProductSizeSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{id ? EDIT_PRODUCT_SIZE_TEXT : ADD_PRODUCT_SIZE_TEXT}</Typography>
            {!id && (
              <Button variant="contained" type="submit">
                {ADD_TEXT}
              </Button>
            )}
          </Box>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12}>
              {getLoading ? (
                <DataLoader columns={12} rows={6} />
              ) : (
                <ProductSizeForm loading={loading} isEdit={!!id} />
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <BackdropLoader
        open={loading}
        text={id ? PRODUCT_SIZE_IS_UPDATED_TEXT : PRODUCT_SIZE_IS_CREATING_TEXT}
      />
    </Fragment>
  );
};

export default Edit;
