import { FC, JSX } from 'react';
import { Grid } from '@mui/material';
// components
import ContactForm from './components/ContactForm';
import SupplierForm from './components/SupplierForm';
// interfaces
import { AddSupplierProps } from 'interfaces';

const AddSupplier: FC<AddSupplierProps> = ({ loading, isEdit = false }): JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item lg={6} md={12}>
        <SupplierForm loading={loading} isEdit={isEdit} />
      </Grid>
      <Grid item lg={6} md={12}>
        <ContactForm loading={loading} isEdit={isEdit} />
      </Grid>
    </Grid>
  );
};

export default AddSupplier;
