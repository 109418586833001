import { EditorState } from 'draft-js';
// graphql, interfaces
import { LoadAddUnit, OrgDashboardPayload, ServingUnitType, SuperDashboardPayload } from 'generated/graphql';
import {
  AddBottleCapacityFormType,
  AddBottlePriceFormType,
  AddBottleSizeFormType,
  AddExcipientFormType,
  AddExcipientLabelFormType,
  AddExcipientTypeFormType,
  AddNewFormulaFormType,
  AddNewIngredientFormType,
  AddNewStaffFormType,
  AddNewUserFormType,
  ProductExcipientFormType,
  AddProductFormulationTypeForm,
  AddProductSizeFormType,
  AddProductTypeFormType,
  AddSubProductTypeFormType,
  AddSupplierFormType,
  AllergensCardType,
  BlendItemType,
  ChangeFormType,
  ContactFormType,
  DosageFormulationFormType,
  EditStaffFormType,
  EditUserFormType,
  FactBoxFormulationFormType,
  FormulaInfoCardFormType,
  FormulaIngredientFormType,
  FormulationFormType,
  HeavyMetalsFormType,
  IngredientBasicInfoCardType,
  IngredientCostInfoCardType,
  IngredientCountriesCardType,
  IngredientDensityCardType,
  IngredientItemFormType,
  IngredientSupplierCardType,
  LoginUserInput,
  NutrientsCardType,
  NutrientsTypeObject,
  OrganizationFormType,
  ProductTypeSelect,
  SaveFormulaAsDraftFormType,
  SelectType,
  StaffRoleFormType,
  SupplierFormType,
  UpdatePasswordFormType,
  UpdateUserProfileType,
  UserFormType,
  AddSweetenerSystemFormType,
  AddSweetenerComponentFormType,
  AddSweetenerElementFormType,
} from 'interfaces';

export const loginUserInitialValues: LoginUserInput = {
  email: '',
  password: '',
};

export const organizationInitialValue: OrganizationFormType = {
  organizationName: '',
  maxUser: '',
};

export const roleInitialValue: StaffRoleFormType = {
  role: '',
};

export const updatePasswordInitialValues: UpdatePasswordFormType = {
  password: '',
};

export const userInitialValues: UserFormType = {
  email: '',
  phone: '',
  lastName: '',
  firstName: '',
};

export const editUserInitialValues: EditUserFormType = {
  email: '',
  phone: '',
  lastName: '',
  firstName: '',
  organizationName: '',
  maxUser: '',
};

export const updateStaffInitialValues: EditStaffFormType = {
  ...userInitialValues,
  ...roleInitialValue,
};

export const addNewStaffInitialValue: AddNewStaffFormType = {
  ...roleInitialValue,
  ...userInitialValues,
  ...updatePasswordInitialValues,
};

export const addNewUserInitialValues: AddNewUserFormType = {
  ...userInitialValues,
  ...organizationInitialValue,
  ...updatePasswordInitialValues,
};

export const selectTypeInitialValues: SelectType = {
  name: '',
  value: '',
  type: '',
};

export const ingredientBasicInfoCardInitialValues: IngredientBasicInfoCardType = {
  name: '',
  latinName: EditorState.createEmpty(),
  tradeMarkName: '',
  scientificName: '',
  type: [],
  category: selectTypeInitialValues,
  subCategory: selectTypeInitialValues,
  productTypes: [],
};

export const ingredientCostInfoCardInitialValues: IngredientCostInfoCardType = {
  amount: `1`,
  showB3: false,
  nutrients: false,
  nutrientName: false,
  cost: '',
  potency: '',
  shelfLife: '',
  suggestedOverage: '',
  gummyOverage: '',
};

export const ingredientSupplierCardInitialValues: IngredientSupplierCardType = {
  primarySupplier: selectTypeInitialValues,
  secondarySupplier: selectTypeInitialValues,
  tertiarySupplier: selectTypeInitialValues,
};

export const ingredientCountriesCardInitialValues: IngredientCountriesCardType = {
  manufacturingCountry: selectTypeInitialValues,
  originCountry: selectTypeInitialValues,
};

export const ingredientDensityCardInitialValues: IngredientDensityCardType = {
  bulkDensity: '',
  lossOnDrying: '',
  tappedDensity: '',
  averageParticleSize: '',
};

export const allergensCardInitialValue: AllergensCardType = {
  allergens: [],
};

export const nutrientsCardInitialValues: NutrientsTypeObject = {
  organic: '',
  moisture: '',
  calories: '',
  protein: '',
  carbohydrates: '',
  dietaryFiber: '',
  totalSugars: '',
  addedSugar: '',
  totalFat: '',
  saturatedFat: '',
  monounsaturatedFat: '',
  polyunsaturatedFat: '',
  transFat: '',
  vitaminA: '',
  biotin: '',
  thiamin: '',
  riboflavin: '',
  niacin: '',
  vitaminB6: '',
  vitaminB12: '',
  vitaminC: '',
  vitaminD: '',
  vitaminE: '',
  magnesium: '',
  zinc: '',
  vitaminK2: '',
  choline: '',
  iodine: '',
  folate: '',
  cholesterol: '',
  calcium: '',
  iron: '',
  potassium: '',
  boron: '',
  sodium: '',
  chloride: '',
  chromium: '',
  copper: '',
  manganese: '',
  molybdenum: '',
  pantothenicAcid: '',
  phosphorus: '',
  selenium: '',
};

export const heavyMetalsInitialValues: HeavyMetalsFormType = {
  arsenic: '',
  cadmium: '',
  lead: '',
  mercury: '',
};

export const nutrientsInitialValues: NutrientsCardType = {
  ingredientInfo: nutrientsCardInitialValues,
};

export const addNewIngredientInitialValues: AddNewIngredientFormType = {
  ...ingredientBasicInfoCardInitialValues,
  ...ingredientCostInfoCardInitialValues,
  ...ingredientSupplierCardInitialValues,
  ...ingredientCountriesCardInitialValues,
  ...ingredientDensityCardInitialValues,
  ...allergensCardInitialValue,
  ...nutrientsInitialValues,
  ...heavyMetalsInitialValues,
};

export const changePasswordInitialValues: ChangeFormType = {
  conPassword: '',
  newPassword: '',
  oldPassword: '',
};

export const productFormulationTypeInitialValues: ProductTypeSelect = {
  name: '',
  value: '',
  type: '',
};

export const IngredientsItemFormInitialValues: IngredientItemFormType = {
  value: '',
  potency: 1,
  name: '',
  ingredientPotency: 1,
};

export const blendItemInitialValues: BlendItemType = {
  name: '',
  ingredients: [],
};

export const addNewFormulaInitialValue: AddNewFormulaFormType = {
  name: '',
  formulaBlend: [],
  formulaIngredients: [],
  ingredients: [],
};

export const productTypeInitialValues: Pick<DosageFormulationFormType, 'productType'> = {
  productType: '',
};

export const formulationValues: FormulationFormType = {
  beadlets: '',
  colorsHex: [],
  bottleCap: '',
  tabletType: '',
  capsuleType: '',
  tabletCoating: '',
  excipientLabel: '',
  innerCapsuleType: '',
  outerCapsuleType: '',
  desiredServingSize: '',
  servingPerContainer: '',
  servingUnit: ServingUnitType.Capsule,
  excipientType: selectTypeInitialValues,

  coatingColor: '',
  coatingColor2: '',
  coatingColor3: '',

  miniTabFormulation: '',

  flavorType: '',
  flavorSystem: selectTypeInitialValues,

  sweetenerType: '',
  sweetenerSystem: selectTypeInitialValues,

  factBoxType: '',
  proteinBase: '',
  veganType: '',

  productSize: selectTypeInitialValues,
  sandCoating: '',
};

export const dosageFormulaFormInitialValues: Omit<DosageFormulationFormType, 'productType'> = {
  productSubType: '',
  ...formulationValues,
  ...productTypeInitialValues,
  productFormulationType: productFormulationTypeInitialValues,
};

export const dosageFormulationFormInitialValues = {
  ...dosageFormulaFormInitialValues,
  ...productTypeInitialValues,
};

export const UpdateUserProfileInitialValues: UpdateUserProfileType = {
  email: '',
  phone: '',
  lastName: '',
  firstName: '',
};

export const factBoxFormulaFormInitialValues: FactBoxFormulationFormType = {
  factBoxType: '',
  formulaIngredients: [],
  ingredients: [],
};

export const formulaInfoCardFormInitialValue: FormulaInfoCardFormType = {
  name: '',
};

export const formulaIngredientFormInitialValues: FormulaIngredientFormType = {
  ingredients: [],
  formulaIngredients: [],
  primary: selectTypeInitialValues,
  secondary: selectTypeInitialValues,
};

export const superInitialValues: SuperDashboardPayload = { ingredients: 0 };
export const orgInitialValues: OrgDashboardPayload = { ingredients: 0 };

export const saveFormulaAsDraftFormInitialValue: SaveFormulaAsDraftFormType = {
  name: '',
};

export const contactInitialValues: ContactFormType = {
  // id: '',
  city: '',
  name: '',
  email: '',
  phoneNo: '',
  zipCode: '',
  address2: '',
  address1: '',
  designation: '',
  state: selectTypeInitialValues,
  country: selectTypeInitialValues,
};

export const supplierInitialValues: SupplierFormType = {
  companyName: '',
  description: '',
};

export const addSupplierInitialValues: AddSupplierFormType = {
  ...contactInitialValues,
  ...supplierInitialValues,
};

export const productTypeInitialValue: AddProductTypeFormType = {
  name: '',
};

export const productSubTypeInitialValue: AddSubProductTypeFormType = {
  name: '',
  productType: selectTypeInitialValues,
};

export const productSizeInitialValue: AddProductSizeFormType = {
  name: '',
  productType: selectTypeInitialValues,
  subProductType: selectTypeInitialValues,
  emptyWtMg: '0',
  min: '0',
  max: '0',
  isActive: true,
  gummyWeight: '0',
};

export const productFormulationTypeInitialValue: AddProductFormulationTypeForm = {
  name: '',
  isActive: true,
  lohInside: '',
  conversionCost: '0',
  isInnerCapTab: false,
  innerComponentCost: '',
  productType: selectTypeInitialValues,
  productSubType: selectTypeInitialValues,
};

export const excipientInitialValue: AddExcipientFormType = {
  name: '',
};

export const excipientLabelInitialValue: AddExcipientLabelFormType = {
  name: '',
};

export const excipientTypeInitialValue: AddExcipientTypeFormType = {
  name: '',
  loadAdd: '',
  loadAddUnit: LoadAddUnit.Mg,
  perKgCost: '',
  excipientLabel: '',
  productType: selectTypeInitialValues,
};

export const productExcipientInitialValue: ProductExcipientFormType = {
  perKgCost: '',
  percentage: '',
  excipient: selectTypeInitialValues,
  suppliers: [],
};

export const bottleSizeInitialValue: AddBottleSizeFormType = {
  cc: '',
  oz: '',
  name: '',
  lidSize: '',
  productTypes: [],
};

export const bottlePriceInitialValue: AddBottlePriceFormType = {
  price: '',
  isCr: false,
  type: '',
  suppliers: [],
  bottleSize: selectTypeInitialValues,
};

export const bottleCapacityInitialValue: AddBottleCapacityFormType = {
  fillingPercentage: '',
  lohCost: '',
  productSize: selectTypeInitialValues,
  productType: selectTypeInitialValues,
  servingSize: selectTypeInitialValues,
  bottleSize: selectTypeInitialValues,
};

export const sweetenerSystemInitialValue: AddSweetenerSystemFormType = {
  name: '',
  mgPerServing: '',
  costPerServing: '',
  nature: '',
  size: '',
  type: '',
  productSize: selectTypeInitialValues,
  productType: selectTypeInitialValues,
  productFormulationType: selectTypeInitialValues,
};

export const sweetenerComponentInitialValue: AddSweetenerComponentFormType = {
  name: '',
  costPerKg: '',
};

export const sweetenerElementInitialValue: AddSweetenerElementFormType = {
  qtPerMg: '',
  sweetenerComponent: selectTypeInitialValues,
  sweetenerSystem: selectTypeInitialValues,
};
