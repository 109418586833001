import { Grid } from '@mui/material';
import { FC, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
// components
import InputController from 'controller/InputController';
import RadioGroupController from 'controller/RadioGroupController';
import TabletCoatingRadioGroup from 'components/common/radioGroups/TabletCoating';
// graphql, constants, interface
import {
  COLOR_TEXT,
  COATING_TEXT,
  FORMULATION_TEXT,
  TABLET_COLOR_MAPPED,
  COATING_COLORS_HEX_TEXT,
  TABLET_FORMULATION_MAPPED,
} from 'constants/index';
import { DosageFormulationFormType } from 'interfaces';
import { ProductTypes, TabletCoatingColor } from 'generated/graphql';

const Mini: FC = () => {
  const { watch } = useFormContext<DosageFormulationFormType>();
  const { coatingColor } = watch();

  return (
    <Fragment>
      <Grid item xs={12} xl={12}>
        <RadioGroupController
          name="miniTabFormulation"
          title={FORMULATION_TEXT}
          options={TABLET_FORMULATION_MAPPED}
          isRequired
        />
      </Grid>
      <Grid item xs={12} xl={12}>
        <TabletCoatingRadioGroup
          isRequired
          name="tabletCoating"
          title={COATING_TEXT}
          productType={ProductTypes.Tablet}
        />
      </Grid>
      <Grid item xs={12} xl={12}>
        <RadioGroupController
          isRequired
          name="coatingColor"
          title={COLOR_TEXT}
          options={TABLET_COLOR_MAPPED}
        />
      </Grid>
      {coatingColor === TabletCoatingColor.Multi ? (
        <Grid item xs={12} xl={12}>
          <InputController isMultiValue name="colorsHex" fieldType="color" title={COATING_COLORS_HEX_TEXT} />
        </Grid>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};
export default Mini;
