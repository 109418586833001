import { Grid } from '@mui/material';
import { FC, Fragment, JSX } from 'react';
import { useFormContext } from 'react-hook-form';
// components
import ExcipientLabelSelector from 'components/common/radioGroups/ExcipientLabel';
import ExcipientTypeSelector from 'components/common/selectors/excipientType';
// constants, graphql, interfaces
import { ProductTypes } from 'generated/graphql';
import { DosageFormulationFormType, ExcipientFieldsProps } from 'interfaces';
import { EXCIPIENT_TYPE_TEXT, selectTypeInitialValues, EXCIPIENT_LABEL_TEXT } from 'constants/index';

const ExcipientFields: FC<ExcipientFieldsProps> = ({ productType = ProductTypes.Tablet }): JSX.Element => {
  const { watch, setValue } = useFormContext<DosageFormulationFormType>();
  const { excipientLabel } = watch();

  const onClear = () => {
    setValue('excipientLabel', '');
    setValue('excipientType', selectTypeInitialValues);
  };

  return (
    <Fragment>
      <Grid item xs={12} xl={12}>
        <ExcipientLabelSelector
          onClear={onClear}
          name="excipientLabel"
          title={EXCIPIENT_LABEL_TEXT}
          onChange={() => {
            setValue('excipientType', selectTypeInitialValues);
          }}
        />
      </Grid>

      <Grid item xs={12} xl={12}>
        <ExcipientTypeSelector
          name="excipientType"
          disabled={!excipientLabel}
          title={EXCIPIENT_TYPE_TEXT}
          productType={productType}
          excipientLabelId={excipientLabel}
        />
      </Grid>
    </Fragment>
  );
};

export default ExcipientFields;
