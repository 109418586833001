// imports
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, MenuItem, TextField } from '@mui/material';
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
// interfaces, constants, helper
import { renderProductFormulationTypes } from 'lib/helper';
import { ProductFormulationTypeProps } from 'interfaces';
import { HTTP_STATUS, selectTypeInitialValues } from 'constants/index';
import { ProductFormulationTypesPayload, useFindAllProductFormulationTypeLazyQuery } from 'generated/graphql';

const ProductFormulationTypes: FC<ProductFormulationTypeProps> = ({
  name,
  title,
  value,
  isActive,
  defaultValue,
  productTypeId,
  handleChange,
  margin = 'dense',
  isClearable = false,
}) => {
  const [options, setOptions] = useState<ProductFormulationTypesPayload['data']>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const updatedOptions = [...renderProductFormulationTypes(options ?? [])];

  const [findAllProductFormulationTypes, { loading }] = useFindAllProductFormulationTypeLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllProductFormulationType } = data;
      const { data: formulationTypes, response } = findAllProductFormulationType || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setOptions(formulationTypes as ProductFormulationTypesPayload['data']);
      }
    },
    onError: () => {
      setOptions([]);
    },
  });

  const fetchAllProductFormulationType = useCallback(async () => {
    await findAllProductFormulationTypes({
      variables: {
        findAllProductFormulationTypeInput: {
          paginationOptions: { page: 1, limit: 10 },
          search: searchQuery,
          ...(productTypeId && { productTypeId }),
          ...(typeof isActive === 'boolean' && { isActive }),
        },
      },
    });
  }, [findAllProductFormulationTypes, searchQuery, isActive, productTypeId]);

  useEffect(() => {
    fetchAllProductFormulationType();
  }, [fetchAllProductFormulationType, searchQuery.length]);

  return (
    <Autocomplete
      disableClearable={!isClearable}
      options={updatedOptions}
      value={value}
      onChange={(_, val) => {
        handleChange(val ?? selectTypeInitialValues);
      }}
      onClose={() => setSearchQuery('')}
      popupIcon={<KeyboardArrowDownRoundedIcon />}
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={({ value: option }, { value: val }) => val === option}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          margin={margin}
          fullWidth
          variant="outlined"
          defaultValue={defaultValue}
          label={title}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}>
          {updatedOptions?.map((item) => {
            const { name, value } = item;
            return (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            );
          })}
        </TextField>
      )}
    />
  );
};

export default ProductFormulationTypes;
