import { FC, useContext } from 'react';
import { Box, Grid } from '@mui/material';
// components
import FormulaInfoCard from './FormulaInfoCard';
import IngredientsList from './IngredientsList';
import FormulaWeightNCost from './FormulaWeightNCost';
import FormulaEstimatorDetail from './FormulaEstimatorDetail';
// graphql, context,
import { getFillPercentage } from 'lib/helper';
import { FormulaDetailContext } from 'contexts/FormulaDetailContext';
import { Attachment, BottleCapType, ProductTypes } from 'generated/graphql';

const Detail: FC = () => {
  const { state, loading } = useContext(FormulaDetailContext);
  const { formula } = state;
  const {
    name,
    mfgCost,
    bottleCap,
    coMfgCost,
    attachment,
    productType,
    capsulePrice,
    bottleCapacity,
    subProductType,
    innerCapsulePrice,
    outerCapsulePrice,
    productFormulationType,
    formulaWeights,
    servingContainer,
  } = formula || {};
  const { name: productTypeName, type } = productType || {};
  const { name: subProductName } = subProductType || {};
  const { name: productFormulationName } = productFormulationType || {};

  const bottleCost = {
    mfgCost: mfgCost ?? 0,
    coMfgCost: coMfgCost ?? 0,
  };

  const { totalWeight } = formulaWeights || {};

  const { fillPercentage, bottleSize, productSize } = bottleCapacity || {};
  const { cc, lidSize } = bottleSize || {};
  const { name: productSizeName, max } = productSize || {};

  const bottleFill = {
    fillPercentage:
      type === ProductTypes.Powder
        ? getFillPercentage(max ?? 1, totalWeight ?? 0, servingContainer ?? 1)
        : fillPercentage ?? 0,
  };

  const packageEstimator = {
    cc: cc ?? 0,
    lidSize: lidSize ?? 0,
    isChildResistant: bottleCap === BottleCapType.ChildResistant ?? false,
    tabletSize: type === ProductTypes.Tablet ? productSizeName || '--' : '',
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} xl={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={6}>
            <FormulaInfoCard />
          </Grid>
          <Grid item xs={12} xl={6}>
            <FormulaWeightNCost costing={formula ?? {}} loading={loading} />
          </Grid>
        </Grid>
        <Box pt={3}>
          <IngredientsList />
        </Box>
      </Grid>

      <Grid item xs={12} xl={4}>
        <FormulaEstimatorDetail
          isDetailPage
          loading={loading}
          heading={name ?? ''}
          capsulePrice={capsulePrice}
          title={`${productTypeName ? `${productTypeName},` : ''} ${
            subProductName ? `${subProductName},` : ''
          } ${productFormulationName}`}
          bottleCost={bottleCost}
          bottleFill={bottleFill}
          packageEstimator={packageEstimator}
          outerCapsulePrice={outerCapsulePrice}
          innerCapsulePrice={innerCapsulePrice}
          attachment={attachment as Attachment}
        />
      </Grid>
    </Grid>
  );
};

export default Detail;
