import { FC, Fragment, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import SupplierForm from './SupplierForm';
import { Alert } from 'components/common/Alert';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import {
  ADD_TEXT,
  HTTP_STATUS,
  ADD_SUPPLIER_TEXT,
  addSupplierInitialValues,
  SUPPLIER_IS_CREATING_TEXT,
} from 'constants/index';
import { AddSupplierFormType } from 'interfaces';
import { supplierSchema } from 'validationSchema';
import { useCreateSupplierMutation } from 'generated/graphql';
import { flexCenterBetween } from 'styles/commonComponentStyle';

const Add: FC = (): JSX.Element => {
  const navigate = useNavigate();

  const methods = useForm<AddSupplierFormType>({
    defaultValues: addSupplierInitialValues,
    resolver: yupResolver(supplierSchema),
  });

  const { handleSubmit } = methods;

  const [createSupplier, { loading: createLoading }] = useCreateSupplierMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createSupplier } = data;
      const { response } = createSupplier || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        navigate(-1);
        Alert.success(message || '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<AddSupplierFormType> = async (data) => {
    const { companyName, description, state, country, phoneNo, ...rest } = data || {};
    const { value, name } = country || {};

    await createSupplier({
      variables: {
        createSupplierInput: {
          contact: {
            ...rest,
            companyName,
            state: state?.value,
            country: name,
            ...(value && { countryCode: value }),
            ...(phoneNo && { phoneNo }),
          },
          supplier: {
            description,
            name: companyName,
          },
        },
      },
    });
  };

  const loading = createLoading;

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{ADD_SUPPLIER_TEXT}</Typography>
            <Button variant="contained" color="primary" type="submit" disabled={loading}>
              {ADD_TEXT}
            </Button>
          </Box>
          <SupplierForm loading={loading} />
        </form>
      </FormProvider>
      <BackdropLoader open={loading} text={SUPPLIER_IS_CREATING_TEXT} />
    </Fragment>
  );
};

export default Add;
